import { ref, reactive } from "vue"
import Swal from "sweetalert2"
import { useRouter } from "vue-router"
import { useRuntimeConfig, useRoute, useCookie } from "#imports"

const session = useCookie("directus_session_token")

// const user = useCookie("pharus_user")

const myUser = reactive({
  isAuthenticated: false,
  isAdmin: false
});

export type UserCredentials = {
  email: string;
  password: string;
};

export type UserAuth = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  location: string;
  title: string;
  avatar: string;
  status: string;
};

export type UserRegistred = {
  first_name: string;
  last_name: string;
  email: string;
  location: string;
  title: string;
  academic_degree: string;
  birth_date: string;
  gender: string;
};

export type UserInfo = UserAuth | "";

const config = useRuntimeConfig();

export function useUserSession() {
  const router = useRouter();

  const localSession = localStorage.getItem("pharus_user")
  const currentUser = localSession ? JSON.parse(localSession) : null;

  myUser.isAuthenticated = currentUser ? true : false
  myUser.isAdmin = (currentUser ? currentUser.role.name : "") === "Administrator"

  const user = {
    isAuthenticated: myUser.isAuthenticated,
    isAdmin: myUser.isAdmin
  }
  const sessionCheck = () => {
    const isCookieUnset = !user.value && !session.value

    if (isCookieUnset && localSession !== null) {
    } else {
      user.value = "";
      router.push("/login");
      Swal.fire({
        title: "Intento fallido",
        text: "Estimado maestro, se requiere iniciar sesión",
        icon: "warning",
      });
    }
  };

  const userSession = async () => {
    try {
      const { data } = await $fetch<{ data: UserAuth }>(
          `${config.public.apiURL}/users/me`,
          {
            params: {
              'fields[]': [
                'id',
                'user_created.first_name',
                'user_created.last_name',
                'user_created.avatar',
                'user_created.role.name',
                'comment'
              ],
              'filter[course_item_id][_eq]': `${currentItem.id}`,
              'sort[]': '-date_created'
            },
            credentials: "include",
          }
      );
      localStorage.setItem("pharus_user", JSON.stringify(data));
      useCookie("pharus_user", { maxAge: 604800 }).value = data.id;
      router.push("/");
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  return {
    sessionCheck,
    currentUser,
    userSession,
    user
  };
}
